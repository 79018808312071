import mediaHelper from 'styled-media-helper';

export const queries = mediaHelper({
  mobile: 400,
  tablet: 767,
  desktop: 1300,
  uhd: 1921,
});

export const sizeConverter = (
  value: number,
  size: 'small' | 'normal' | 'big' | 'tiny' | 'mini' = 'normal'
) => {
  switch (size) {
    case 'mini':
      return value * 0.3;
    case 'tiny':
      return value * 0.5;
    case 'small':
      return value * 0.7;
    case 'normal':
      return value;
    case 'big':
      return value * 1.3;
  }
};
