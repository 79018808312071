import { lighten } from 'polished';
import React from 'react';
import { Mark } from '../../components/Mark';
import { Paragraph } from '../../components/Paragraph';
import { Section } from '../../components/Section';
import { StyledSectionContent } from '../../components/Section/content';
import { Spacer } from '../../components/Spacer';

export const ExpertiseSection = () => (
  <>
    <Section background='#0D0221' align='flex-start'>
      <Spacer size='8vh' />
    </Section>
    <Section background='#0D0221' align='flex-end'>
      <Paragraph
        size='normal'
        fadeDirection='right'
        color={lighten(0.1, '#ffffff')}
        backdrop={lighten(0.2, '#0D0221')}
        backdropShadow
        align='right'
      >
        <strong>Expert</strong>ise
      </Paragraph>
    </Section>
    <Section
      gradient={{
        direction: 'to bottom',
        from: '#0D0221',
        to: lighten(0.07, '#0D0221'),
      }}
    >
      <StyledSectionContent>
        <Spacer size='5vh' />
        <Paragraph size='small' fadeDirection='up' color='#e1d1ff'>
          I could list all the different technologies from Git through Lua to
          NodeJS, but these skills can be learned by anyone. The knowledge I
          value the most is inherently the overall experience from being able to
          bring a project to life from it's initial{' '}
          <Mark backdrop={lighten(0.15, '#0D0221')}>
            sketch, through design, prototyping and testing to publishing
          </Mark>
          .
        </Paragraph>
        <Spacer size='4vh' />
        <Paragraph size='small' fadeDirection='up' color='#e1d1ff'>
          Quick learner, decision maker, expressive, passionate and easily
          inspired. Never-ending{' '}
          <Mark backdrop={lighten(0.2, '#0D0221')}>flow of ideas</Mark> is
          spiraling through my brain at every moment.
        </Paragraph>
        <Spacer size='4vh' />
        <Paragraph size='small' fadeDirection='up' color='#e1d1ff'>
          <Mark backdrop={lighten(0.3, '#0D0221')}>
            I don't define myself by the work I've done, but the work I want to
            do.
          </Mark>{' '}
          With that said, below are some of my current and past projects.
        </Paragraph>
      </StyledSectionContent>
    </Section>
  </>
);
