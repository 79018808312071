import React from 'react';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';
import { queries, sizeConverter } from '../../constants/style';

export interface ITitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  color?: string;
  size?: 'small' | 'normal' | 'big' | 'tiny';
  children: any;
  fadeDirection?: 'down' | 'left' | 'up' | 'right';
  align?:
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end';
  fadeDuration?: number;
  fadeDelay?: number;
}

export const StyledTitle = styled.h1<ITitleProps>`
  font-family: 'Bree Serif', sans-serif;
  color: ${({ color }) => color || '#ffffff'};
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: ${({ align }) => align || 'space-between'};
  align-items: center;

  ${queries.down('tablet')} {
    font-size: ${({ size }) => sizeConverter(40, size)}px;
    font-weight: 300;
  }

  ${queries.only('tablet')} {
    font-size: ${({ size }) => sizeConverter(60, size)}px;
    font-weight: 200;
  }

  ${queries.only('desktop')} {
    font-size: ${({ size }) => sizeConverter(75, size)}px;
    font-weight: 200;
  }

  ${queries.only('uhd')} {
    font-size: ${({ size }) => sizeConverter(103, size)}px;
    font-weight: 300;
  }
`;

export const Title = ({
  children,
  color,
  size = 'normal',
  fadeDirection = 'down',
  fadeDuration,
  fadeDelay,
  align,
}: ITitleProps) => (
  <Fade
    direction={fadeDirection}
    delay={fadeDelay || 0}
    duration={fadeDuration || 900}
    triggerOnce
    fraction={0.9}
    style={{ width: '100%' }}
  >
    <StyledTitle color={color} size={size} align={align}>
      {children}
    </StyledTitle>
  </Fade>
);
