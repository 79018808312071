import { lighten } from 'polished';
import React from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import styled from 'styled-components';
import DekoracieMilo from '../../assets/DekoracieMilo.png';
import LowCode from '../../assets/LowCode.png';
import Qorus from '../../assets/Qorus.png';
import SelfService from '../../assets/SelfService.png';
import SliceThePie from '../../assets/SliceThePie.png';
import StyleTrial from '../../assets/StyleTrial.png';
import { Paragraph } from '../../components/Paragraph';
import { ProjectContent } from '../../components/Project';
import { ProjectImage } from '../../components/ProjectImage';
import { Section } from '../../components/Section';
import { Spacer } from '../../components/Spacer';

const StyledSmallImages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WebSection = () => (
  <>
    <Section background='#1b0124' align='flex-start'>
      <Spacer size='8vh' />
    </Section>
    <Section background='#1b0124' align='flex-start'>
      <Paragraph
        size='normal'
        fadeDirection='left'
        fadeDelay={300}
        color={lighten(0.1, '#ffffff')}
        backdrop={'#8300b0'}
        backdropShadow
        align='left'
      >
        <strong>Web</strong>
      </Paragraph>
    </Section>
    <Section background='#1b0124' align='flex-start'>
      <Spacer size='5vh' />
    </Section>
    <Section
      gradient={{ direction: 'to bottom', from: '#1b0124', to: '#0D0221' }}
    >
      <Spacer size='1vh' />
      <ProjectContent align='center' smallPadding>
        <Fade
          direction='left'
          fraction={0.5}
          triggerOnce
          style={{ width: '100%' }}
        >
          <ProjectImage src={DekoracieMilo} />
        </Fade>
      </ProjectContent>
      <ProjectContent align='center' smallPadding>
        <Fade
          direction='right'
          fraction={0.5}
          triggerOnce
          style={{ width: '100%' }}
        >
          <ProjectImage src={SliceThePie} />
        </Fade>
      </ProjectContent>
    </Section>
    <Section background='#0D0221'>
      <ProjectContent align='center' smallPadding>
        <Fade
          direction='left'
          fraction={0.5}
          triggerOnce
          style={{ width: '100%' }}
        >
          <ProjectImage src={StyleTrial} />
        </Fade>
      </ProjectContent>
      <ProjectContent align='center' smallPadding>
        <Zoom fraction={0.5} triggerOnce>
          <StyledSmallImages>
            <ProjectImage src={Qorus} style={{ width: '45%' }} />

            <ProjectImage src={LowCode} style={{ width: '45%' }} />
          </StyledSmallImages>
        </Zoom>
        <Spacer size='4vh' />
        <Zoom fraction={0.5} delay={400} triggerOnce>
          <StyledSmallImages>
            <ProjectImage src={SelfService} style={{ width: '45%' }} />

            <Paragraph
              size='tiny'
              style={{ width: '45%' }}
              align='center'
              fadeDirection='right'
              fadeDelay={600}
            >
              And more...
            </Paragraph>
          </StyledSmallImages>
        </Zoom>
      </ProjectContent>
    </Section>
  </>
);
