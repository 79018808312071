import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export interface IMarkProps {
  color?: string;
  backdrop: string;
  hasShadow?: boolean;
}

export const Mark = styled.mark<IMarkProps>`
  color: ${({ color }) => color || '#ffffff'};
  background-color: ${({ backdrop }) => rgba(backdrop, 0.5)};
  position: relative;

  ${({ hasShadow, backdrop }) =>
    hasShadow &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 130%;
        height: 100%;
        margin-top: -10%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        background-color: ${rgba(backdrop, 0.2)};
      }
    `}
`;
