import { lighten } from 'polished';
import React from 'react';
import { Mark } from '../../components/Mark';
import { Paragraph } from '../../components/Paragraph';
import { Section } from '../../components/Section';
import { StyledSectionContent } from '../../components/Section/content';
import { Spacer } from '../../components/Spacer';

export const AboutSection = () => (
  <>
    <Section background='#151120' align='flex-start'>
      <Spacer size='8vh' />
    </Section>
    <Section background='#151120' align='flex-start'>
      <Paragraph
        size='normal'
        fadeDirection='left'
        color={lighten(0.1, '#ffffff')}
        backdrop={lighten(0.2, '#151120')}
        backdropShadow
        align='left'
      >
        About <strong>me</strong>
      </Paragraph>
    </Section>
    <Section
      gradient={{
        direction: 'to bottom',
        from: '#151120 0%',
        to: `#0D0221`,
      }}
    >
      <StyledSectionContent>
        <Spacer size='5vh' />
        <Paragraph size='small' fadeDirection='up' color='#e1d1ff'>
          I am a multi-talented human from Ostrava, Czech Republic, with 10+
          years experience in wide range of software development disciplines.
        </Paragraph>
        <Spacer size='4vh' />
        <Paragraph size='small' fadeDirection='up' color='#e1d1ff'>
          <Mark backdrop={lighten(0.2, '#151120')}>
            Over the years I have worn many shoes. Web development and app
            development, product and experience design and even consulting and
            game design & development non-profesionally.
          </Mark>
        </Paragraph>
        <Spacer size='4vh' />
        <Paragraph size='small' fadeDirection='up' color='#e1d1ff'>
          Fueled by my passion, I will always keep learning, challenging myself
          and do interesting things that keep pushing me forward just a bit
          more.
        </Paragraph>
      </StyledSectionContent>
    </Section>
  </>
);
