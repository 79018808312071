import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import MainBG from '../../assets/main_bg.jpg';
import { Paragraph } from '../../components/Paragraph';
import { Section } from '../../components/Section';
import { StyledSectionContent } from '../../components/Section/content';
import { Spacer } from '../../components/Spacer';
import { queries } from '../../constants/style';

export const StyledTitleSection = styled.div`
  position: relative;
  flex: 1;

  background: #0d0221 url(${MainBG});
  background-size: cover;
  background-repeat: no-repeat;

  ${queries.down('tablet')} {
    background-position: 30%;
  }

  ${queries.up('desktop')} {
    background-position: center;
  }
`;

export const StyledBgCover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  background-color: ${rgba('#0D0221', 0.4)};
  color: #ffffff;
`;

export const TitleSection = () => (
  <Section fill>
    <StyledTitleSection>
      <StyledBgCover>
        <StyledSectionContent>
          <Paragraph
            size='big'
            fadeDirection='down'
            fadeDelay={100}
            backdrop='#0b061a'
            align='center'
          >
            Hi, I am <strong>Foxhoundn</strong>. Also <strong>Filip</strong>.
          </Paragraph>
          <Spacer size='3vh' />
          <Paragraph
            size='normal'
            fadeDirection='up'
            fadeDelay={500}
            backdrop='#151120'
            align='center'
          >
            Czech based freelancer who designs and builds digital products for
            other humans to enjoy.
          </Paragraph>
        </StyledSectionContent>
      </StyledBgCover>
    </StyledTitleSection>
  </Section>
);
