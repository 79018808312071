import React from 'react';
import styled from 'styled-components';
import { queries } from '../../constants/style';

export interface ISectionContentProps {
  children?: any;
}

export const StyledSectionContent = styled.div<ISectionContentProps>`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;

  ${queries.down('tablet')} {
    padding: 3vh 11vw;
  }

  ${queries.up('desktop')} {
    padding: 1vw 7vw;
  }
`;

export const SectionContent = ({ children }: ISectionContentProps) => (
  <StyledSectionContent>{children}</StyledSectionContent>
);
