import React from 'react';
import styled, { css } from 'styled-components';
import { queries } from '../../constants/style';

export interface IProjectContentProps {
  children?: any;
  background?: string;
  gradient?: {
    direction: string;
    from: string;
    to: string;
  };
  skew?: string;
  align?: 'flex-start' | 'flex-end' | 'center';
  smallPadding?: boolean;
}

export const StyledProjectContent = styled.div<IProjectContentProps>`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: ${({ align }) => align || 'center'};

  ${({ background, gradient }) => {
    if (gradient) {
      return css`
        background-image: linear-gradient(
          ${gradient.direction},
          ${gradient.from},
          ${gradient.to}
        );
      `;
    }

    return css`
      background-color: ${background || 'transparent'};
    `;
  }}

  ${queries.down('tablet')} {
    width: 100%;
    padding: 7vw;
  }

  ${queries.up('desktop')} {
    padding: ${({ smallPadding }) => (smallPadding ? '2vw' : '7vw')};
    flex: 1;
  }
`;

export const ProjectContent = ({ children, ...rest }: IProjectContentProps) => (
  <StyledProjectContent {...rest}>{children}</StyledProjectContent>
);
