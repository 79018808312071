import styled from 'styled-components';
import { queries } from '../../constants/style';

export interface ITagProps {
  color: string;
  children: any;
}

export const Tag = styled.div<ITagProps>`
  padding: 5px 10px;
  border-radius: 5px;

  &:not(:last-child) {
    margin-right: 1vw;
  }

  ${queries.down('tablet')} {
    font-size: 10px;
    font-weight: 300;
  }

  ${queries.only('tablet')} {
    font-size: 20px;
    font-weight: 200;
  }

  ${queries.only('desktop')} {
    font-size: 25px;
    font-weight: 200;
  }

  ${queries.only('uhd')} {
    font-size: 33px;
    font-weight: 300;
  }

  color: #ffffff;
  background-color: ${({ color }) => color};
`;
