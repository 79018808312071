import React from 'react';
import { Fade } from 'react-awesome-reveal';
import styled, { css } from 'styled-components';
import { queries, sizeConverter } from '../../constants/style';
import { Mark } from '../Mark';

export interface IParagraphProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  color?: string;
  size?: 'small' | 'normal' | 'big' | 'tiny' | 'mini';
  children: any;
  fadeDirection?: 'down' | 'left' | 'up' | 'right';
  fadeDuration?: number;
  fadeDelay?: number;
  backdrop?: string;
  skew?: 'right' | 'left';
  align?: 'center' | 'left' | 'right' | 'justify';
  backdropShadow?: boolean;
}

export const StyledParagraph = styled.p<IParagraphProps>`
  color: ${({ color }) => color || '#ffffff'};

  ${queries.down('tablet')} {
    font-size: ${({ size }) => sizeConverter(25, size)}px;
    line-height: ${({ size }) => sizeConverter(35, size)}px;
    font-weight: 300;
    text-align: ${({ align }) => align || 'justify'};
  }

  ${queries.up('tablet')} {
    text-align: ${({ align }) => align || 'justify'};
  }

  ${queries.only('tablet')} {
    font-size: ${({ size }) => sizeConverter(40, size)}px;
    line-height: ${({ size }) => sizeConverter(55, size)}px;
    font-weight: 200;
  }

  ${queries.only('desktop')} {
    font-size: ${({ size }) => sizeConverter(55, size)}px;
    line-height: ${({ size }) => sizeConverter(75, size)}px;
    font-weight: 200;
  }

  ${queries.only('uhd')} {
    font-size: ${({ size }) => sizeConverter(73, size)}px;
    line-height: ${({ size }) => sizeConverter(98, size)}px;
    font-weight: 300;
  }

  strong {
    font-weight: 400;
  }

  ${({ skew }) =>
    skew &&
    css`
      transform: rotate(${skew === 'right' ? '4deg' : '-4deg'});
    `}
`;

export const Paragraph = ({
  children,
  color,
  size = 'normal',
  fadeDirection = 'down',
  fadeDuration,
  fadeDelay,
  backdrop,
  skew,
  align,
  backdropShadow,
  ...rest
}: IParagraphProps) => (
  <Fade
    direction={fadeDirection}
    delay={fadeDelay || 0}
    duration={fadeDuration || 900}
    fraction={0.9}
    triggerOnce
    {...rest}
  >
    <StyledParagraph
      color={color}
      size={size}
      backdrop={backdrop}
      skew={skew}
      align={align}
    >
      {backdrop ? (
        <Mark color={color} backdrop={backdrop} hasShadow={backdropShadow}>
          {children}
        </Mark>
      ) : (
        children
      )}
    </StyledParagraph>
  </Fade>
);
