import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { queries } from '../../constants/style';

export interface ISectionProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: any;
  background?: string;
  gradient?: {
    direction: string;
    from: string;
    to: string;
  };
  fill?: boolean;
  withDetails?: boolean;
  align?: 'flex-start' | 'flex-end' | 'center';
  reverseOnMobile?: boolean;
}

export const StyledSection = styled.div<ISectionProps>`
  display: flex;
  width: 100%;
  position: relative;

  ${({ fill }) =>
    fill &&
    css`
      min-height: 100vh;
    `};

  ${({ background, gradient }) => {
    if (gradient) {
      return css`
        background-image: linear-gradient(
          ${gradient.direction},
          ${gradient.from},
          ${gradient.to}
        );
      `;
    }

    return css`
      background-color: ${background || 'transparent'};
    `;
  }}

  ${queries.down('tablet')} {
    flex-flow: ${({ reverseOnMobile }) =>
      reverseOnMobile ? 'column-reverse' : 'column'};
  }

  ${queries.up('tablet')} {
    ${({ align }) =>
      align &&
      css`
        justify-content: ${align};
      `};
  }

  ${({ withDetails }) =>
    withDetails &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 5vw;
        height: 5vw;
        border-top: 2px solid ${rgba('#d0b7ff', 0.3)};
        border-left: 2px solid ${rgba('#d0b7ff', 0.1)};
        left: 5vw;
        top: 5vw;
      }

      &::after {
        content: '';
        position: absolute;
        width: 5vw;
        height: 5vw;
        border-bottom: 2px solid ${rgba('#d0b7ff', 0.3)};
        border-right: 2px solid ${rgba('#d0b7ff', 0.1)};
        right: 5vw;
        bottom: 5vw;
      }
    `}
`;

export const Section = ({ children, ...rest }: ISectionProps) => (
  <StyledSection {...rest}>{children}</StyledSection>
);
