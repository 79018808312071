import { lighten } from 'polished';
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Mark } from '../../components/Mark';
import { Paragraph } from '../../components/Paragraph';
import { Section } from '../../components/Section';
import { StyledSectionContent } from '../../components/Section/content';
import { Spacer } from '../../components/Spacer';
import { Tag } from '../../components/Tag';
import { Title } from '../../components/Title';

export const PresentSection = () => (
  <>
    <Section background='#0D0221' align='flex-start'>
      <Spacer size='8vh' />
    </Section>
    <Section background='#0D0221' align='flex-end'>
      <Paragraph
        size='normal'
        fadeDirection='right'
        color={lighten(0.1, '#ffffff')}
        backdrop={lighten(0.3, '#0D0221')}
        backdropShadow
        align='right'
      >
        Present
      </Paragraph>
    </Section>
    <Section
      gradient={{
        direction: 'to bottom',
        from: '#0D0221 0%',
        to: `#151120`,
      }}
    >
      <StyledSectionContent>
        <Spacer size='5vh' />
        <Paragraph size='small' fadeDirection='up' color='#e1d1ff'>
          While being a freelancer, I am currently exclusively working for{' '}
          <Mark backdrop={lighten(0.15, '#0D0221')}>
            <a
              href='https://qoretechnologies.com'
              target='_blank'
              rel='noreferrer'
            >
              Qore Technologies
            </a>
          </Mark>
          , where we are building tools and solutions for low-code environments,
          and helping AI focused companies deliver incredible products.
        </Paragraph>
        <Spacer size='4vh' />
        <Paragraph size='small' fadeDirection='up' color='#e1d1ff'>
          <Mark backdrop={lighten(0.2, '#0D0221')}>
            As of this moment, I focus mainly on NodeJS, TypeScript and ReactJS
            and their respective ecosystems and use these tools to build apps,
            games and web products.
          </Mark>
        </Paragraph>
        <Spacer size='4vh' />
        <Paragraph size='small' fadeDirection='up' color='#e1d1ff'>
          If you would still like to get in touch, even if only to talk,{' '}
          <Mark backdrop={lighten(0.35, '#0D0221')}>please don't hesitate</Mark>{' '}
          to do so using one of the options below.
        </Paragraph>
      </StyledSectionContent>
    </Section>
    <Section background='#151120' align='flex-start'>
      <Spacer size='5vh' />
    </Section>
    <Section background='#151120' align='flex-start'>
      <StyledSectionContent>
        <Title align='center'>
          <Fade direction='down' triggerOnce>
            <div>
              <a
                href='mailto:filip.witosz@gmail.com'
                style={{ display: 'inline-block', marginRight: '1vw' }}
              >
                <Tag color={lighten(0.3, '#0D0221')}>
                  filip.witosz@gmail.com
                </Tag>
              </a>

              <a
                href='https://twitter.com/foxhoundn'
                target='_blank'
                rel='noreferrer'
                style={{ display: 'inline-block', marginRight: '1vw' }}
              >
                <Tag color='#4287f5'>Twitter</Tag>
              </a>

              <a
                href='https://instagram.com/foxhoundn.dev'
                target='_blank'
                rel='noreferrer'
                style={{ display: 'inline-block', marginRight: '1vw' }}
              >
                <Tag color={lighten(0.2, '#2c0443')}>Instagram</Tag>
              </a>

              <a
                href='https://www.linkedin.com/in/foxhoundn'
                target='_blank'
                rel='noreferrer'
                style={{ display: 'inline-block' }}
              >
                <Tag color='#0077b5'>Linkedin</Tag>
              </a>
            </div>
          </Fade>
        </Title>
      </StyledSectionContent>
    </Section>
    <Section background='#151120' align='flex-start'>
      <Spacer size='2vh' />
    </Section>
    <Section background='#151120' align='flex-start'>
      <StyledSectionContent>
        <Paragraph size='mini' fadeDirection='up' color='#e1d1ff'>
          foxhoundn.me [2021] All rights reserved © Filip Witosz [Foxhoundn] |
          This site does not use any cookies | All content present on this site
          in any form is property of Filip Witosz or relevant parties
        </Paragraph>
      </StyledSectionContent>
    </Section>
  </>
);
