import { darken, lighten } from 'polished';
import React from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import ReactPlayer from 'react-player';
import AppStore from '../../assets/appstore_white.svg';
import LinkbraryVideo from '../../assets/Linkbrary.mp4';
import Linkbrary from '../../assets/Linkbrary.png';
import Shuflic from '../../assets/Shuflic.png';
import ShuflicVideo from '../../assets/Shuflic2.mp4';
import { Link } from '../../components/Link';
import { Mark } from '../../components/Mark';
import { Paragraph } from '../../components/Paragraph';
import { ProductLinks } from '../../components/ProductLinks';
import { ProjectContent } from '../../components/Project';
import { Section } from '../../components/Section';
import { Spacer } from '../../components/Spacer';
import { Tag } from '../../components/Tag';
import { Title } from '../../components/Title';
import { Colors } from '../../constants/colors';

export const AppsSection = () => (
  <>
    <Section background={lighten(0.07, '#0D0221')} align='flex-start'>
      <Spacer size='8vh' />
    </Section>
    <Section background={lighten(0.07, '#0D0221')}>
      <Paragraph
        size='normal'
        fadeDirection='left'
        color={lighten(0.1, '#ffffff')}
        backdrop={'#4287f5'}
        backdropShadow
        align='left'
      >
        <strong>Apps</strong>
      </Paragraph>
    </Section>
    <Section
      gradient={{
        direction: 'to bottom',
        from: lighten(0.07, '#0D0221'),
        to: lighten(0.07, '#0D0221'),
      }}
    >
      <Spacer size='5vh' />
      <ProjectContent align='flex-start'>
        <Title size='tiny' fadeDirection='down'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={Linkbrary}
              style={{ height: '5vh', marginRight: '20px' }}
              alt='Linkbrary Logo'
            />{' '}
            Linkbrary
          </div>{' '}
          <Tag color={Colors.GREEN}>Released</Tag>
        </Title>
        <Spacer size='3vh' />
        <Paragraph size='tiny' fadeDirection='left' fadeDelay={100}>
          Mac® app <Mark backdrop={darken(0.13, Colors.BLUE)}>Linkbrary</Mark>{' '}
          helps you build the{' '}
          <Mark backdrop={darken(0.155, Colors.BLUE)}>
            ultimate link library
          </Mark>{' '}
          and allows you to save your links by simply dragging them to the menu
          bar icon... or you can create one manually.{' '}
          <Mark backdrop={darken(0.168, Colors.BLUE)}>
            It's always up to you
          </Mark>
          .
        </Paragraph>
        <Spacer size='2vh' />
        <Paragraph size='tiny' fadeDirection='left' fadeDelay={300}>
          Once dropped, the link is saved.{' '}
          <Mark backdrop={darken(0.174, Colors.BLUE)}>That's it</Mark>. You can
          optionally label it, describe it, add it to groups and give it a
          custom icon & color ... and don't forget the coolest feature:
        </Paragraph>
        <Spacer size='2vh' />
        <Paragraph
          size='small'
          fadeDirection='left'
          backdrop={darken(0.18, Colors.BLUE)}
          fadeDelay={500}
        >
          Adding your own links to the Mac® menu bar.
        </Paragraph>
        <Spacer size='2vh' />
        <Paragraph size='tiny' fadeDirection='left' fadeDelay={700}>
          Having your links in the menu bar means that your important website is
          always just a{' '}
          <Mark backdrop={darken(0.2, Colors.BLUE)}>click away</Mark>.
        </Paragraph>
        <Spacer size='2vh' />
        <ProductLinks>
          <a
            href='https://apps.apple.com/app/linkbrary/id1562973396?mt=12'
            target='_blank'
            rel='noreferrer'
          >
            <img src={AppStore} alt='Shuflic Logo' />
          </a>
          <Fade direction='up'>
            <Link
              color='#ffffff'
              href='https://linkbrary.app'
              target='_blank'
              rel='noreferrer'
            >
              https://linkbrary.app
            </Link>
          </Fade>
        </ProductLinks>
      </ProjectContent>
      <ProjectContent align='center' smallPadding>
        <Zoom duration={600} fraction={0.8} triggerOnce>
          <ReactPlayer
            url={LinkbraryVideo}
            playing
            playsinline
            muted
            width='80%'
            height='auto'
            loop
            style={{
              borderRadius: '10px',
              margin: '0 auto',
            }}
          />
        </Zoom>
      </ProjectContent>
    </Section>
    <Section
      gradient={{
        direction: 'to bottom',
        from: lighten(0.07, '#0D0221'),
        to: '#2c0443',
      }}
      reverseOnMobile
    >
      <Spacer size='5vh' />
      <ProjectContent align='center' smallPadding>
        <Zoom duration={600} fraction={0.8} triggerOnce>
          <ReactPlayer
            url={ShuflicVideo}
            playing
            playsinline
            muted
            width='80%'
            height='auto'
            loop
            style={{
              borderRadius: '10px',
              margin: '0 auto',
            }}
          />
        </Zoom>
      </ProjectContent>
      <ProjectContent align='flex-start'>
        <Title size='tiny' fadeDirection='down'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={Shuflic}
              style={{ height: '5vh', marginRight: '20px' }}
              alt='Shuflic Logo'
            />{' '}
            Shuflic
          </div>{' '}
          <Tag color={Colors.GREEN}>Released</Tag>
        </Title>
        <Spacer size='3vh' />
        <Paragraph size='tiny' fadeDirection='right' fadeDelay={100}>
          Mac &#174; app Shuflic - from the czech word šuflík (drawer) - is a
          digital version of that one drawer at home, where we all put our
          (mostly) important documents.
        </Paragraph>
        <Spacer size='2vh' />
        <Paragraph
          size='small'
          fadeDirection='right'
          backdrop={lighten(0.2, '#2c0443')}
          fadeDelay={300}
        >
          With Shuflic, saving your notes, article bits, code snippets or even
          recipes or lyrics for later is a matter of a single shortcut!
        </Paragraph>
        <Spacer size='2vh' />
        <Paragraph size='tiny' fadeDirection='right' fadeDelay={500}>
          You can think of Shufic as a clipboard on steroids.
        </Paragraph>
        <Spacer size='2vh' />
        <ProductLinks>
          <a
            href='https://apps.apple.com/app/shuflic/id1559682710?mt=12'
            target='_blank'
            rel='noreferrer'
          >
            <img src={AppStore} alt='Shuflic Logo' />
          </a>
          <Fade direction='up'>
            <Link
              color='#ffffff'
              href='https://shuflic.app'
              target='_blank'
              rel='noreferrer'
            >
              https://shuflic.app
            </Link>
          </Fade>
        </ProductLinks>
      </ProjectContent>
    </Section>
  </>
);
