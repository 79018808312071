// @ts-ignore
import SEO from '@americanexpress/react-seo';
import React from 'react';
import styled from 'styled-components';
import { Normalize } from 'styled-normalize';
import { Reset } from 'styled-reset';
import './App.css';
import { queries } from './constants/style';
import { AboutSection } from './sections/About';
import { AppsSection } from './sections/Apps';
import { ExpertiseSection } from './sections/Expertise';
import { GamesSection } from './sections/Games';
import { PresentSection } from './sections/Present';
import { TitleSection } from './sections/Title';
import { WebSection } from './sections/Web';

const StyledAppWrapper = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  color: #ffffff;
  overflow: hidden;

  ${queries.down('tablet')} {
    font-size: 30px;
    font-weight: 300;
  }

  ${queries.only('tablet')} {
    font-size: 40px;
    font-weight: 200;
  }

  ${queries.only('desktop')} {
    font-size: 45px;
    font-weight: 200;
  }

  ${queries.only('uhd')} {
    font-size: 53px;
    font-weight: 300;
  }

  * {
    box-sizing: border-box;
  }

  a,
  a:visited,
  a:active,
  a:focus {
    color: #fff;
    text-decoration: none;
  }
`;

function App() {
  return (
    <StyledAppWrapper>
      <SEO
        title='Hi, I am Foxhoundn'
        description='Foxhoundn - Filip Witosz - Czech based freelancer who designs and builds digital products for other humans to enjoy.'
        keywords={[
          'foxhoundn',
          'Filip',
          'Witosz',
          'Filip Witosz',
          'programmer',
          'designer',
          'apps',
          'games',
          'blog',
          'portfolio',
          'code',
        ]}
        siteUrl='https://foxhoundn.me'
        robots={['index', 'follow']}
      />
      <Reset />
      <Normalize />
      <TitleSection />
      <AboutSection />
      <ExpertiseSection />
      <AppsSection />
      <GamesSection />
      <WebSection />
      <PresentSection />
    </StyledAppWrapper>
  );
}

export default App;
