import styled from 'styled-components';
import { queries } from '../../constants/style';

export const ProductLinks = styled.div`
  display: flex;

  ${queries.down('tablet')} {
    flex-flow: column;
    justify-content: center;
  }

  ${queries.up('tablet')} {
    a {
      margin-right: 10px;
    }
    align-items: center;
  }
`;
