import React from 'react';
import styled from 'styled-components';

export const StyledSpacer = styled.div`
  width: 1px;
  height: ${({ size }: { size?: string }) => size};
`;

export const Spacer = ({ size }: { size?: string }) => (
  <StyledSpacer size={size} />
);
