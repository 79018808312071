import { lighten } from 'polished';
import React from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import ReactPlayer from 'react-player';
import GuardianVideo from '../../assets/goo.mp4';
import SynthaxVideo from '../../assets/synthax.mp4';
import Synthax from '../../assets/synthax_logo.png';
import { Link } from '../../components/Link';
import { Mark } from '../../components/Mark';
import { Paragraph } from '../../components/Paragraph';
import { ProjectContent } from '../../components/Project';
import { Section } from '../../components/Section';
import { Spacer } from '../../components/Spacer';
import { Tag } from '../../components/Tag';
import { Title } from '../../components/Title';

export const GamesSection = () => (
  <>
    <Section background='#2c0443' align='flex-start'>
      <Spacer size='8vh' />
    </Section>
    <Section background='#2c0443' align='flex-end'>
      <Paragraph
        size='normal'
        fadeDirection='right'
        fadeDelay={300}
        color={lighten(0.1, '#ffffff')}
        backdrop={'#f542f2'}
        backdropShadow
        align='right'
      >
        <strong>Games</strong>
      </Paragraph>
    </Section>
    <Section background='#2c0443'>
      <ProjectContent align='flex-start'>
        <Title size='tiny' fadeDirection='down'>
          <img
            src={Synthax}
            style={{ height: 'calc(6vh + 3vw)', marginLeft: '-3%' }}
            alt='Synthax Logo'
          />
          <Tag color='#ebb031'>In development</Tag>
        </Title>
        <Spacer size='3vh' />
        <Paragraph size='tiny' fadeDirection='left' fadeDelay={100}>
          Multiplayer 3rd person arena shooter powered by the{' '}
          <Mark backdrop={lighten(0.35, '#2c0443')}>BabylonJS</Mark> engine and{' '}
          <Mark backdrop={lighten(0.3, '#2c0443')}>Colyseus</Mark> multiplayer
          server framework. Written in TypeScript, with ReactJS driving the
          client.
        </Paragraph>
        <Spacer size='2vh' />
        <Paragraph size='tiny' fadeDirection='left' fadeDelay={300}>
          In Synthax, battles are{' '}
          <Mark backdrop={lighten(0.25, '#2c0443')}>
            fast but also tactical
          </Mark>
          . Utilise multiple abilities such as invisibility or personal force
          fields and dozen of weapons (yes, even sci-fi chainsaw) to overcome
          opponents in various modes including classics like{' '}
          <Mark backdrop={lighten(0.2, '#2c0443')}>DM, TDM and CTF</Mark>.
        </Paragraph>
        <Spacer size='2vh' />
        <Paragraph size='tiny' fadeDirection='left' fadeDelay={500}>
          Currently in development, alpha testing planned for{' '}
          <Mark backdrop={lighten(0.1, '#2c0443')}>later this year</Mark>.
        </Paragraph>
        <Spacer size='2vh' />
        <Fade direction='up'>
          <div style={{ display: 'flex' }}>
            <Link
              color='#ffffff'
              href='https://discord.gg/gR6MEtFaWW'
              target='_blank'
              rel='noreferrer'
            >
              Discord
            </Link>
            <Link
              color='#ffffff'
              href='https://synthax.gg'
              target='_blank'
              rel='noreferrer'
            >
              https://synthax.gg
            </Link>
          </div>
        </Fade>
      </ProjectContent>
      <ProjectContent align='flex-start' smallPadding>
        <Zoom duration={600} fraction={0.8} triggerOnce>
          <ReactPlayer
            url={SynthaxVideo}
            playing
            playsinline
            muted
            width='80%'
            height='auto'
            loop
            style={{
              borderRadius: '10px',
              margin: '0 auto',
            }}
          />
        </Zoom>
      </ProjectContent>
      <Spacer size='5vh' />
    </Section>
    <Section
      gradient={{ direction: 'to bottom', from: '#2c0443', to: '#1b0124' }}
      reverseOnMobile
    >
      <ProjectContent align='center' smallPadding>
        <Zoom duration={600} fraction={0.8} triggerOnce>
          <ReactPlayer
            url={GuardianVideo}
            playing
            playsinline
            muted
            width='80%'
            height='auto'
            loop
            style={{
              borderRadius: '10px',
              margin: '0 auto',
            }}
          />
        </Zoom>
      </ProjectContent>
      <ProjectContent align='flex-start'>
        <Title size='tiny' fadeDirection='down'>
          Guardian of Ormos
          <Tag color='#666'>Backlog</Tag>
        </Title>
        <Spacer size='3vh' />
        <Paragraph size='tiny' fadeDirection='right' fadeDelay={100}>
          My very first take on a video game from 2013. Written in jQuery and
          PHP, GoO was supposed to be an{' '}
          <Mark backdrop={lighten(0.2, '#1b0124')}>incredible RPG</Mark>!
        </Paragraph>
        <Spacer size='2vh' />
        <Paragraph size='tiny' fadeDirection='right' fadeDelay={300}>
          King of Ormos, Meelan, has called for you in a very dire situation.
          His wife,{' '}
          <Mark backdrop={lighten(0.2, '#1b0124')}>
            queen Magda has been taken by the evil sorcerer
          </Mark>
          , you need to save her. You need to be the Guardian of Ormos.
        </Paragraph>
        <Spacer size='2vh' />
        <Paragraph size='tiny' fadeDirection='right' fadeDelay={500}>
          With it's own{' '}
          <Mark backdrop={lighten(0.3, '#1b0124')}>map builder</Mark>, world
          building was easy and fun. Unfortunately life got in the way, and at
          the moment GoO has it's own honorary place in my backlog.
        </Paragraph>
        <Spacer size='2vh' />
        <Fade direction='up'>
          <Link
            color='#ffffff'
            href='https://youtu.be/M2Qa2GlfPgg'
            target='_blank'
            rel='noreferrer'
          >
            Full video
          </Link>
        </Fade>
      </ProjectContent>
    </Section>
  </>
);
