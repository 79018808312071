import { rgba } from 'polished';
import styled from 'styled-components';
import { queries } from '../../constants/style';

export interface ILinkProps {
  color: string;
  children: any;
}

export const Link = styled.a<ILinkProps>`
  font-family: 'Bree Serif', sans-serif;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 1vw;
  }

  ${queries.down('tablet')} {
    font-size: 15px;
    font-weight: 300;
  }

  ${queries.only('tablet')} {
    font-size: 20px;
    font-weight: 200;
  }

  ${queries.only('desktop')} {
    font-size: 25px;
    font-weight: 200;
  }

  ${queries.only('uhd')} {
    font-size: 30px;
    font-weight: 300;
  }

  transition: all 0.2s linear;
  color: #151120 !important;
  background-color: ${({ color }) => color};
  box-sizing: border-box;

  &:hover {
    background-color: ${({ color }) => rgba(color, 0)};
    color: #ffffff !important;
  }
`;
